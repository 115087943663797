/** @format */

import { useWindowWidth } from '@/hooks/useWindowWidth';
import { GTMLinkClick, GTMSectionClick } from '@/lib/google-tag-manager';
import { MenuItem } from '@/types/page';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import ArrowDown from '../../assets/icons/arrow_down.svg';
import { Icon } from '../ui/Icon';
import { NavbarLinkSecond } from '../ui/Typography';
import { Dropdown, MenuButton, Wrapper } from './NavDropdown.style';

export const NavDropdown: FC<Props> = ({ title, links, id }) => {
  const theme = useContext(ThemeContext);
  const windowWidth = useWindowWidth();
  const { locale, route } = useRouter();
  const ref = useRef<HTMLLIElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement | null>(null);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);
  const [align, setAlign] = useState('left');

  const menuItems = useMemo(() => links.map(item => item.fields), [links]);

  const onClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);
    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (dropdownRef.current) {
      if (!windowWidth) return;
      const boundingDropdown = dropdownRef?.current?.getBoundingClientRect();
      const boundingMenuButtonRef = menuButtonRef?.current?.getBoundingClientRect();
      if (!boundingMenuButtonRef) return;
      if (windowWidth - boundingMenuButtonRef.x < boundingDropdown.width) setAlign('right');
      else setAlign('left');
    }
  }, [windowWidth]);

  return (
    <Wrapper ref={ref} onMouseEnter={() => setIsMenuOpen(true)} onMouseLeave={() => setIsMenuOpen(false)}>
      <MenuButton
        type="button"
        aria-haspopup="menu"
        aria-expanded={isMenuOpen}
        $active={isMenuOpen}
        ref={menuButtonRef}
        onClick={() => GTMSectionClick(id)}
      >
        {title} <Icon icon={ArrowDown} width={12} height={6} color="white" />
      </MenuButton>
      <Dropdown ref={dropdownRef} tabIndex={-1} $show={isMenuOpen} $align={align}>
        {menuItems.map(l => (
          <li key={l.label} onClick={() => setIsMenuOpen(false)}>
            {l.url && (
              <NavbarLinkSecond
                color={theme!.color.teal.dark}
                item={l.url}
                locale={locale}
                data-page-active={route === `/${l.url}`}
                onClick={() => GTMLinkClick(l.url.fields.link.fields.id)}
              >
                {l.label}
              </NavbarLinkSecond>
            )}
          </li>
        ))}
      </Dropdown>
    </Wrapper>
  );
};

type Props = {
  id: string;
  title: string;
  links: Entry<MenuItem>[];
};
