/** @format */

import styled from 'styled-components';

export const ContainerWrapper = styled.div<{ $color?: string; $textColor?: string }>`
  background: ${({ $color, theme }) => $color ?? theme.color.teal.dark};
  overflow: hidden;
  position: relative;
`;

export const Container = styled.div`
  --padding: ${({ theme }) => theme.container.defaultPadding};
  width: min(1440px, 100% - var(--padding));
  margin-inline: auto;
`;

export const ContainerRightSide = styled.div`
  --padding: ${({ theme }) => theme.container.smallPadding};
  --default-padding: ${({ theme }) => theme.container.defaultPadding};
  margin-inline: auto 0;
  width: min(calc(100% - (100% - 1440px) / 2), 100% - var(--padding));
`;

export const ContainerLeftSide = styled.div`
  --padding: ${({ theme }) => theme.container.smallPadding};
  --default-padding: ${({ theme }) => theme.container.defaultPadding};
  margin-inline: 0 auto;
  width: min(calc(100% - (100% - 1440px) / 2), 100% - var(--padding));
`;

export const ContainerSM = styled.div`
  text-align: center;
`;

export const ContainerMD = styled.div`
  text-align: center;
`;

export const ContainerFW = styled.div`
  text-align: center;
`;
