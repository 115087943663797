/** @format */

import styled from 'styled-components';

export const Wrapper = styled.li`
  position: relative;
`;

export const Dropdown = styled.ul<{ $show?: boolean; $align: string }>`
  list-style-type: none;
  position: absolute;
  border-radius: 0rem 0rem 0.5625rem 0.5625rem;
  background-color: white;
  padding: 0.62rem 0 !important;
  ${({ $align }) => ($align === 'left' ? `left:0;` : `right:0;`)};
  margin-top: calc(6.25rem - 1px);
  line-height: calc(1.875rem * 1.5);
  transition: all 0.3s ease-in-out;
  transform-origin: top center;
  z-index: 2;

  ${({ $show }) => ($show ? `transform:scaleY(1);` : `transform:scaleY(0);`)};

  a {
    white-space: nowrap;
    font-size: ${({ theme }) => theme.font.size[1]};
    color: ${({ theme }) => theme.color.teal.dark};
    padding: 1rem;

    &:hover,
    &[data-page-active='true'] {
      color: ${({ theme }) => theme.color.teal.light};
    }
  }
`;

export const MenuButton = styled.button<{ $active?: boolean }>`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[0.9375]};
  color: ${({ theme, $active }) => ($active ? theme.color.yellow : theme.color.white)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: none;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    color: ${({ theme }) => theme.color.yellow};

    i {
      background-color: ${({ theme }) => theme.color.yellow} !important;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background-color: ${({ theme, $active }) => ($active ? theme.color.yellow : 'transparent')};
  }
`;
