/** @format */

export const normalizeExternalLink = (url: string) => {
  const specialLinkRegex =
    /^(mailto:|tel:|sms:|whatsapp:|geo:|tg:|skype:|bitcoin:|spotify:|fax:|data:|ftp:|irc:|xmpp:|ircs:|gopher:|news:|nntp:|feed:|cvs:|magnet:|ssh:|git:|steam:|sip:|sips:|market:|file:|chrome-extension:|intent:|itms:|itms-apps:|pcast:|rv:|ed2k:|webcal:|wtai:|file:|data:|javascript:|vbscript:|data:image\/[a-z]+;base64,)/;

  if (specialLinkRegex.test(url) || /^https?:\/\//.test(url)) {
    return url;
  } else {
    return 'https://' + url;
  }
};
