/** @format */

import Close from '@/assets/icons/close.svg';
import EnIcon from '@/assets/icons/en_icon.svg';
import EsIcon from '@/assets/icons/es_icon.svg';
import FrIcon from '@/assets/icons/fr_icon.svg';
import Hamburger from '@/assets/icons/hamburger.svg';
import ItIcon from '@/assets/icons/it_icon.svg';
import PtIcon from '@/assets/icons/pt_icon.svg';
import Logo from '@/assets/logo-blue.svg';
import { useLanguageSwitcherContext } from '@/context/LanguageSwitcherContext';
import { i18nContext } from '@/context/i18nContext';
import { useGtmLabel } from '@/hooks/useGtmLabel';
import { GTMChangeLanguageClick, GTMHeaderClick, GTMLinkClick, GTMSectionClick } from '@/lib/google-tag-manager';
import { MenuItem } from '@/types/page';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, Fragment, useEffect, useRef, useState } from 'react';
import theme from '../../theme/styled-theme';
import { Icon } from '../ui/Icon';
import { InboundLink } from '../ui/Link';
import { NavbarLinkSecond, NavigationLink } from '../ui/Typography';
import {
  ChangeLanguage,
  LangWrapper,
  MobileLogoWrapper,
  MobileNavigationLinkList,
  Panel,
  SectionTitle,
  SubItemsLinks,
  Wrapper,
} from './MobileNav.style';

export const MobileNav: FC<Props> = ({ items, landing }) => {
  const { locale, route, locales } = useRouter();
  const { slugs } = useLanguageSwitcherContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const displayLocales = locales?.filter(l => l !== 'default') ?? [];
  const { getGTMLabel } = useGtmLabel();

  const onClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsMenuOpen(false);
      document.body.classList.remove('fixed');
    }
  };

  const localeIcon = (locale: string) => {
    switch (locale) {
      case 'en':
        return EnIcon;
      case 'fr':
        return FrIcon;
      case 'es':
        return EsIcon;
      case 'it':
        return ItIcon;
      case 'pt':
        return PtIcon;
      default:
        return null;
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);
    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  const toggleDropdown = () => {
    setIsMenuOpen(prev => !prev);
    document.body.classList.toggle('fixed');
  };

  const renderNavItems = (items: MenuItem[]) => {
    return items.map(item => {
      const gtmLabel = getGTMLabel(item);
      return (
        <Fragment key={item.label}>
          {item.url && (
            <li>
              <NavigationLink
                item={item.url}
                locale={locale}
                data-active={route === `/${item.url}`}
                color={theme.color.teal.light}
                onClick={() => GTMLinkClick(gtmLabel, 'menu_section')}
              >
                {item.label}
              </NavigationLink>
            </li>
          )}
          {item.subItems && (
            <li>
              <SectionTitle color={theme.color.teal.light} onClick={() => GTMSectionClick(item.id)}>
                {item.label}
              </SectionTitle>
              <SubItemsLinks aria-label={item.label} onClick={toggleDropdown}>
                {item.subItems.map(({ fields: item }) => (
                  <NavbarLinkSecond
                    key={item.label}
                    item={item.url!}
                    color={theme.color.teal.dark}
                    onClick={() => GTMLinkClick(gtmLabel)}
                  >
                    {item.url?.fields.label}
                  </NavbarLinkSecond>
                ))}
              </SubItemsLinks>
            </li>
          )}
        </Fragment>
      );
    });
  };

  return (
    <i18nContext.Consumer>
      {t => (
        <Wrapper ref={ref} $locale={locale}>
          <button
            type="button"
            onClick={() => {
              toggleDropdown();
              GTMHeaderClick('burger_menu');
            }}
            title={t['aria-label']['openMenu']}
          >
            <Icon icon={Hamburger} color={theme.color.yellow} width={44} height={44} />
          </button>
          <Panel $show={isMenuOpen} id="mobile-navigation">
            <MobileLogoWrapper>
              <InboundLink href="/" locale={locale}>
                <Image src={Logo} alt="logo" width={179} height={52} />
              </InboundLink>
              <button type="button" onClick={toggleDropdown} title={t['aria-label']['openMenu']}>
                <Icon icon={Close} color={theme.color.teal.light} width={44} height={44} />
              </button>
            </MobileLogoWrapper>
            <MobileNavigationLinkList>{renderNavItems(items)}</MobileNavigationLinkList>
            {Object.keys(slugs).length > 0 && (
              <LangWrapper>
                <p>Select language</p>
                <ChangeLanguage>
                  {displayLocales.map(l => (
                    <li key={l}>
                      <InboundLink
                        href={slugs[l]}
                        locale={l}
                        aria-label={l}
                        urlExtension={!landing}
                        onClick={() => {
                          setIsMenuOpen(false);
                          GTMChangeLanguageClick(l, 'menu');
                        }}
                      >
                        <Icon icon={localeIcon(l)} width={28} height={28} />
                      </InboundLink>
                    </li>
                  ))}
                </ChangeLanguage>
              </LangWrapper>
            )}
          </Panel>
        </Wrapper>
      )}
    </i18nContext.Consumer>
  );
};

type Props = {
  items: MenuItem[];
  landing?: boolean;
};
