/** @format */

interface NewsletterFormProps {
  locale?: string;
}
export const NewsletterForm: React.FC<NewsletterFormProps> = ({ locale }) => {
  const klaviyoClass =
    locale === 'it'
      ? 'klaviyo-form-X4ViFi'
      : locale === 'fr'
      ? 'klaviyo-form-QT8QNZ'
      : locale === 'es'
      ? 'klaviyo-form-U3LzZK'
      : locale === 'pt'
      ? 'klaviyo-form-WLEMrb'
      : 'klaviyo-form-YAMfGr'; //default is english

  return <div className={klaviyoClass} />;
};
