/** @format */

import styled from 'styled-components';

type Props = {
  icon: string;
  color?: string;
  hoverColor?: string;
  width?: number;
  height?: number;
};

export const Icon = styled.i.attrs<Props>(props => {
  if (props.color) {
    return {
      style: {
        maskImage: `url(${props.icon})`,
        WebkitMaskImage: `url(${props.icon})`,
        maskSize: '100% 100%',
        WebkitMaskSize: '100% 100%',
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        backgroundColor: props.color,
        transition: 'background-color .2s linear',
      },
    };
  } else {
    return {
      style: {
        backgroundImage: `url(${props.icon})`,
        backgroundSize: 'cover',
      },
    };
  }
})<Props>`
  display: inline-block;
  width: ${({ width }) => (width ? width : 24)}px;
  height: ${({ height }) => (height ? height : 24)}px;

  &:hover {
    background-color: ${({ color, hoverColor, icon }) =>
      hoverColor ? hoverColor : color ? color : `url(${icon})`} !important;
  }
`;
