/** @format */

import styled from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';
import { MagicLink } from '../../utils/magicLink';

//Huge Headers Title - HeaderLarge Title
export const H1 = styled.h1<{ color?: string; keywordColor?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => `clamp(${theme.font.size[2.8125]}, 2.2917rem + 2.3148vw, ${theme.font.size[4.375]})`};
  font-style: normal;
  font-weight: 400;
  line-height: clamp(3.1875rem, 2.625rem + 2.5vw, 4.875rem);
  letter-spacing: clamp(0.02813rem, -0.0062rem + 0.1528vw, 0.13125rem);
  color: ${({ color, theme }) => color ?? theme.color.white};
  padding-bottom: 2.5rem;

  em {
    font-family: ${({ theme }) => theme.font.accent};
    font-size: ${({ theme }) => `clamp(${theme.font.size[2.8125]}, 2.2917rem + 2.3148vw, ${theme.font.size[4.875]})`};
    line-height: clamp(3.1875rem, 2.5417rem + 2.8704vw, 5.125rem);
    letter-spacing: clamp(0.03125rem, -0.0071rem + 0.1704vw, 0.14625rem);
    font-style: normal;
    font-weight: 700;
    color: ${({ keywordColor, theme }) => keywordColor ?? theme.color.yellow};
  }
`;

export const SmallH1 = styled.h1<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.accent};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1.125]}, 2.2917rem + 2.3148vw, ${theme.font.size[1.375]})`};
  font-style: normal;
  font-weight: 400;
  line-height: 4rem;
  letter-spacing: clamp(0.03375rem, 0.0313rem + 0.0111vw, 0.04125rem);
  color: ${({ color, theme }) => color ?? theme.color.teal.dark};
  ${breakpointUpTo.md`
  line-height: inherit;
  `}
  ${breakpointUpTo.sm`
  font-weight: 600;
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-family: ${({ theme }) => theme.font.title};
    font-size: ${({ theme }) => `clamp(${theme.font.size[2.8125]}, 2.2917rem + 2.3148vw, ${theme.font.size[4.375]})`};
    font-style: normal;
  }
`;

//Huge Headers Keywords
export const H1Keywords = styled.h1<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.accent};
  font-size: ${({ theme }) => `clamp(${theme.font.size[3.125]}, 2.2917rem + 2.3148vw, ${theme.font.size[4.875]})`};
  line-height: clamp(3.1875rem, 2.5417rem + 2.8704vw, 5.125rem);
  letter-spacing: clamp(0.03125rem, -0.0071rem + 0.1704vw, 0.14625rem);
  font-style: normal;
  font-weight: 700;
  color: ${({ color, theme }) => color ?? theme.color.black};
`;

//Titolazioni 1° livello e Frasi Grandi
export const H2 = styled.h2<{ $color?: string; $keywordColor?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1.75]},  1.2917rem + 2.037vw, ${theme.font.size[3.125]})`};
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2.1875rem, 1.6458rem + 2.4074vw, 3.8125rem);
  letter-spacing: clamp(0.035rem, 0.0258rem + 0.0407vw, 0.0625rem);
  color: ${({ $color, theme }) => $color && $color};

  em {
    font-family: ${({ theme }) => theme.font.accent};
    font-style: normal;
    font-weight: 700;
    line-height: clamp(2.1875rem, 1.5833rem + 2.6852vw, 4rem);
    letter-spacing: clamp(0.04125rem, 0.0317rem + 0.0426vw, 0.07rem);
    color: ${({ $keywordColor, theme }) => $keywordColor ?? theme.color.black};
  }
`;

//Keywords Titolazioni 2° livello
export const H2Keywords = styled.h2<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.accent};
  font-size: ${({ theme }) => `clamp(${theme.font.size[2.0625]}, 1.5833rem + 2.1296vw, ${theme.font.size[3.5]})`};
  font-style: normal;
  font-weight: 700;
  line-height: clamp(2.1875rem, 1.5833rem + 2.6852vw, 4rem);
  letter-spacing: clamp(0.04125rem, 0.0317rem + 0.0426vw, 0.07rem);
  color: ${({ color, theme }) => color ?? theme.color.black};
`;

//Titolazioni 2° livello
export const H3 = styled.h3<{ color?: string; keywordColor?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1.5]}, 1.3702rem + 0.5769vw, ${theme.font.size[2.0625]})`};
  font-style: normal;
  font-weight: 400;
  line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
  letter-spacing: clamp(0.03rem, 0.0274rem + 0.0115vw, 0.04125rem);
  color: ${({ color, theme }) => color ?? theme.color.black};

  em {
    font-family: ${({ theme }) => theme.font.accent};
    font-size: ${({ theme }) => `clamp(${theme.font.size[1.75]}, 1.6058rem + 0.6410vw, ${theme.font.size[2.375]})`};
    font-style: normal;
    font-weight: 700;
    line-height: clamp(1.75rem, 1.6058rem + 0.641vw, 2.375rem);
    color: ${({ keywordColor, theme }) => keywordColor ?? theme.color.black};
  }
`;

// Desktop/Title 2° Key Words
export const H3Keywords = styled.h3<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.accent};
  font-size: ${({ theme }) => theme.font.size[2.375]};
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem; /* 100% */
  color: ${({ color, theme }) => color ?? theme.color.black};
`;

//Titolazioni 3° livello
export const H4 = styled.h4<{ color?: string; keywordColor?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1.125]}, 1.0417rem + 0.3704vw,${theme.font.size[1.375]});`};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: clamp(0.0225rem, 0.0208rem + 0.0074vw, 0.0275rem);
  color: ${({ color, theme }) => color ?? theme.color.black};

  em {
    font-family: ${({ theme }) => theme.font.title};
    font-size: ${({ theme }) => `clamp(${theme.font.size[1.125]}, 1.0417rem + 0.3704vw,${theme.font.size[1.375]});`};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: clamp(0.0225rem, 0.0208rem + 0.0074vw, 0.0275rem);
    color: ${({ keywordColor, theme }) => keywordColor ?? theme.color.black};
  }
`;

/* Desktop/Header */
export const Kicker = styled.p<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.accent};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1.125]}, 1.0417rem + 0.3704vw,${theme.font.size[1.375]});`};
  font-style: normal;
  font-weight: 600;
  line-height: clamp(1.25rem, 1.0833rem + 0.7407vw, 1.75rem);
  letter-spacing: clamp(0.03375rem, 0.0313rem + 0.0111vw, 0.04125rem);
  color: ${({ color, theme }) => color ?? theme.color.yellow};
  padding-bottom: 3.19rem;
`;

export const Subtitle = styled.h6<{ $color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1]}, 0.9167rem + 0.3704vw,${theme.font.size[1.25]});`};
  font-style: normal;
  font-weight: 400;
  line-height: clamp(1.25rem, 1.0833rem + 0.7407vw, 1.6875rem);
  letter-spacing: clamp(0.03375rem, 0.0313rem + 0.0111vw, 0.0375rem);
  color: ${({ $color }) => $color && $color};
`;

export const DataNumbers = styled.span<{ $color?: string }>`
  font-family: ${({ theme }) => theme.font.accent};
  font-size: ${({ theme }) => `clamp(${theme.font.size['4.375']}, 4.2083rem + 0.7407vw, ${theme.font.size['4.875']})`};
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  color: ${({ $color }) => $color && $color};
`;

export const DataUnitMeasure = styled.span<{ $color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) =>
    `clamp(${theme.font.size['2.8125']},  2.6458rem + 0.7407vw, ${theme.font.size['3.3125']})`};
  font-style: normal;
  font-weight: 300;
  line-height: 1.3;
  color: ${({ $color }) => $color && $color};
`;

//Testo lungo grande
export const PBig = styled.p<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1.4375]}, 1.3333rem + 0.4630vw, ${theme.font.size[1.75]});`};
  font-style: normal;
  font-weight: 300;
  line-height: clamp(2.3125rem, 2.0896rem + 0.9907vw, 2.98125rem);
  letter-spacing: clamp(0.02875rem, 0.0267rem + 0.0093vw, 0.035rem);
  color: ${({ color }) => color && color};
  white-space: pre-line;
`;

export const NavigationLink = styled(MagicLink)<{ color?: string; activeColor?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[0.9375]};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ color, theme }) => color ?? theme.color.white};

  &:hover,
  &[data-active='true'] {
    color: ${({ activeColor, theme }) => activeColor ?? theme.color.yellow};
  }
`;

export const NavigationTitleLink = styled.p<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[0.9375]};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ color, theme }) => color ?? theme.color.teal.dark};
`;

export const FooterInfo = styled.span<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[0.6875]};
  font-style: normal;
  font-weight: 300;
  line-height: 1.3125rem;
  letter-spacing: 0.0275rem;
  color: ${({ color, theme }) => color ?? theme.color.teal.dark};
`;

export const FooterInfoLink = styled(MagicLink)<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[0.6875]};
  font-style: normal;
  font-weight: 300;
  line-height: 1.3125rem;
  letter-spacing: 0.0275rem;
  color: ${({ color, theme }) => color ?? theme.color.teal.dark};

  &:hover,
  &[data-active='true'] {
    color: ${({ theme }) => theme.color.teal.dark};
  }
`;

export const P = styled.p<{ $color?: string }>`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1]}, 0.9583rem + 0.1852vw, ${theme.font.size[1.125]});`};
  font-style: normal;
  font-weight: 300;
  line-height: clamp(1.375rem, 1.3333rem + 0.1852vw, 1.5rem);
  letter-spacing: clamp(0.03rem, 0.0287rem + 0.0056vw, 0.03375rem);
  color: ${({ $color }) => $color && $color};
  white-space: pre-line;
`;

export const NavbarLinkSecond = styled(MagicLink)<{ color?: string }>`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.font.size[1]};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ color, theme }) => color ?? theme.color.black};

  &:hover,
  &[data-active='true'] {
    color: ${({ theme }) => theme.color.teal.light};
  }
`;
