/** @format */

import styled from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';

export const NavbarContainer = styled.nav<{ $locale: string | undefined; $landing?: boolean }>`
  --padding-block: ${({ $landing }) => ($landing ? 'clamp(1.406rem, 1.0413rem + 1.6207vw, 2.5rem)' : '2.5rem')};
  --padding-inline: ${({ $landing }) => ($landing ? 'clamp(0.5rem, 0.25rem + 1.1111vw, 1.25rem)' : '1.25rem')};
  background-color: ${({ theme }) => theme.color.teal.dark};

  ${({ $landing }) =>
    !$landing &&
    `
    position: sticky;
    top: 0;
    z-index: 999;
  `}

  & > div {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    & > * {
      margin-block: auto;

      ${breakpointUpTo.xl`
        img {
          margin-block: 1.2rem;
        }`}
    }

    ${({ $landing }) =>
      !$landing &&
      `
      ${breakpointUpTo.xl`
        img {
          margin-block: 1.5rem;
        }`}
      `}
  }

  ${({ $landing }) =>
    $landing &&
    `
      ${breakpointUpTo.md`
      img {
        height: 2rem;
        width: auto;
      }
  `}
  `}
`;

export const NavbarList = styled.ul<{ $locale: string | undefined; $highlightLast?: boolean }>`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${breakpointUpTo.xl`
        display: none;
  `}

  & > *:not(div) {
    display: flex;
    align-items: stretch;

    & > * {
      padding: var(--padding-block) var(--padding-inline);
    }
  }

  & > div {
    margin-block: auto;
    margin-left: var(--padding-inline);
  }

  ${({ $highlightLast, theme }) =>
    $highlightLast &&
    `
      & > li:last-of-type {
        background: ${theme.color.teal.light};
      }
  `}
`;
