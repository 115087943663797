/** @format */

import styled, { css } from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';
import Image from 'next/image';
import { FooterInfo, NavigationTitleLink } from '../ui/Typography';

export const WrapperLogo = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-width: 21.3125rem;
`;

export const WrapperLeftFooter = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const WrapperRightFooter = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const StyledLogo = styled(Image)`
  margin-right: 3rem;
`;

export const StyledFooter = styled.footer`
  width: 100%;
`;

export const FooterWrapper = styled.div`
  padding-top: 3.62rem;
  padding-bottom: 5.06rem;
  display: grid;
  grid-template-columns: 21.3125rem 1fr;
  grid-gap: 6.88rem;
  ${breakpointUpTo.xl`
      grid-gap: 3.75rem;
  `}

  ${breakpointUpTo.md`
      padding-bottom: 3.75rem;
  `}

  ${breakpointUpTo.lg`
    grid-template-columns: 1fr;
  `}
`;

// See https://styled-components.com/docs/api#transient-props
export const Column = styled.div<{ $grow?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 217px;

  ${breakpointUpTo.xl`
      max-width: 100%;
  `};

  gap: 0.5em;

  & > * {
    align-self: ${props => props.$grow && 'flex-end'};
  }

  ul {
    text-align: ${props => props.$grow && 'end'};
    color: ${({ theme }) => theme.color.gray.dark};
  }
  span {
    color: ${({ theme }) => theme.color.gray.dark};
  }
`;

export const Separator = styled.hr<{ $mobile?: boolean }>`
  width: 100%;
  height: 0.125rem;
  color: ${({ theme }) => theme.color.gray};
  ${breakpointUpTo.md`
   width: ${({ $mobile }: any) => $mobile && '100%'};
  `};
`;

export const TitleGroup = styled(NavigationTitleLink)`
  padding-bottom: 1.05rem;
  position: relative;

  ${breakpointUpTo.md`
      padding-bottom: 0.32rem;
  `};
`;

export const WrapperRoute = styled(Column)<{ $home: boolean }>`
  gap: 1.25rem;

  ${({ $home }) => {
    if ($home)
      return css`
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 2;
          ${breakpointUpTo.xl`
          grid-area: 1 / 1 / 2 / 2;
          `}
          ${breakpointUpTo.md`
          grid-area: 1 / 1 / 2 / 2;
          `}
        }
        &:nth-child(2) {
          grid-area: 1 / 2 / 3 / 3;
          ${breakpointUpTo.xl`
          grid-area: 1 / 2 / 2 / 3;
          `}
          ${breakpointUpTo.md`
          grid-area: 2 / 1 / 3 / 2;
          `}
        }
        &:nth-child(3) {
          grid-area: 1 / 3 / 2 / 4;
          ${breakpointUpTo.xl`
          grid-area: 2 / 1 / 3 / 2; 
          `}
          ${breakpointUpTo.md`
          grid-area: 3 / 1 / 4 / 2;
          `}
        }
        &:nth-child(4) {
          grid-area: 2 / 3 / 3 / 4;
          ${breakpointUpTo.xl`
          grid-area: 3 / 1 / 4 / 2;
          `}
          ${breakpointUpTo.md`
          grid-area: 4 / 1 / 5 / 2; 
          `}
        }
        &:nth-child(5) {
          grid-area: 1 / 4 / 3 / 5;
          ${breakpointUpTo.xl`
          grid-area: 2 / 2 / 4 / 3; 
          `}
          ${breakpointUpTo.md`
          grid-area: 5 / 1 / 6 / 2;
          `}
        }

        &:not(:last-child) {
          padding-bottom: 3.81rem;
          ${breakpointUpTo.sm`
      padding-bottom: 3.38rem;
  `};
        }
        ${breakpointUpTo.md`
      padding-bottom: 3.18rem;
      gap: .62rem;
  `};
        ${breakpointUpTo.sm`
      padding-bottom: 3.38rem;
  `};
      `;

    return css`
      &:not(:last-child) {
        padding-bottom: 3.81rem;
        ${breakpointUpTo.sm`
      padding-bottom: 3.38rem;
  `};
      }

      ${breakpointUpTo.md`
      padding-bottom: 3.18rem;
      gap: .62rem;
  `};

      ${breakpointUpTo.sm`
      padding-bottom: 3.38rem;
  `};

      &:nth-child(3) {
        ${breakpointUpTo.md`
        grid-column: 1/3; 
    `};
      }

      &:last-child {
        ${breakpointUpTo.md`
        grid-row: 3/3; 
        grid-column: 1/3; 
    `};

        ${breakpointUpTo.sm`
      padding-bottom: 0;
    `};
      }
    `;
  }};
`;

export const WrapperRouteList = styled.div<{ $home: boolean }>`
  padding-top: 1rem;
  display: grid;
  grid-column-gap: 1.25rem;
  grid-template-columns: ${props => (props.$home ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)')};

  ${breakpointUpTo.md`
      padding-top: 0rem;
  `}

  ${({ $home }) => {
    if ($home)
      return css`
        ${breakpointUpTo.xl`
      grid-template-columns: repeat(2, 1fr);
  `}
        ${breakpointUpTo.md`
      grid-template-columns: repeat(1, 1fr);
  `}
      `;

    return css`
      ${breakpointUpTo.xl`
      grid-template-columns: repeat(2, 1fr);
  `}
    `;
  }}
`;

export const WrapperTerms = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.62rem;
  padding-top: 2.88rem;
  padding-bottom: 4.88rem;
  justify-content: space-between;

  ${breakpointUpTo.sm`
      flex-direction: column;
  `}
`;

export const LegalInfo = styled(FooterInfo)`
  max-width: 950px;
  min-width: 200px;
`;

export const ChangeLanguageFooterWrapper = styled.div`
  ${breakpointUpTo.lg`
      display: none;
  `}
`;

export const SocialsWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

export const ChangeLanguageFooterWrapperHidden = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PoliciesWrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  ${breakpointUpTo.sm`
      flex-direction: column;
      gap: .62rem
  `}
`;
