/** @format */

import styled from 'styled-components';
import { breakpointFrom } from '../../theme/mixins';
import { NavigationTitleLink } from '../ui/Typography';

export const Wrapper = styled.div<{ $locale: string | undefined }>`
  ${breakpointFrom.xl`
        display: none;
      `}

  button {
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    aspect-ratio: 1;
  }
`;

export const Panel = styled.div<{ $show?: boolean }>`
  position: fixed;
  inset: 0;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  overflow-y: auto;
  z-index: 999;
  background-color: ${({ theme }) => theme.color.white};
  transition: transform 0.3s ease-in-out;
  transform-origin: center right;
  padding: 1.75rem var(--padding-inline);

  img {
    margin-block: 0 !important;
  }

  ${({ $show }) => ($show ? `transform:translateX(0);` : `transform:translateX(100%);`)};
`;

export const MobileLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LangWrapper = styled.div`
  margin-top: 3em;
  p {
    font-size: ${({ theme }) => theme.font.size[1]};
    text-align: left;
    line-height: 2.5;
    font-family: ${({ theme }) => theme.font.body};
    color: ${({ theme }) => theme.color.black};
  }
`;

export const ChangeLanguage = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  list-style: none;
  width: 100%;

  a {
    display: grid;
    place-items: center;
    border-radius: 50%;
    aspect-ratio: 1;
  }

  i {
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.black};
  }
`;

export const MobileNavigationLinkList = styled.ul`
  display: grid;
  gap: 3.5rem;
  margin-top: 1.5rem;
  list-style-type: none;
`;

export const SubItemsLinks = styled.div`
  display: grid;
  gap: 1.25rem;
`;

export const SectionTitle = styled(NavigationTitleLink)`
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.color.teal.light};
    margin-block: 0.5rem 1rem;
  }
`;
