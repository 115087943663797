/** @format */

import styled from 'styled-components';
import { Icon } from '../ui/Icon';

export const IconBtn = styled.button<{ $active?: boolean; variant: 'light' | 'dark' }>`
  --accent-color: ${({ theme, variant }) => (variant === 'dark' ? theme.color.black : theme.color.white)};
  --border-color: ${({ theme, variant }) => (variant === 'dark' ? 'transparent' : theme.color.white)};
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  text-transform: capitalize;
  color: var(--accent-color);
  width: 3.75rem;
`;

export const Arrow = styled.i`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-top: 5px solid var(--accent-color);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
`;

export const Dropdown = styled.ul<{ $show?: boolean; $align: string }>`
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  list-style-type: none;
  background-color: ${({ theme }) => theme.color.white};
  padding: 1rem 1.25rem;
  font-weight: 500;
  border: 1px solid ${({ theme }) => theme.color.teal.dark};
  transform-origin: bottom center;
  transition: all 0.3s ease-in-out;
  z-index: 2;

  ${({ $show, $align }) =>
    $show
      ? `transform: translateY(${$align === 'top' ? '-110%' : '30%'}) scaleY(1);`
      : `transform: translateY(${$align === 'top' ? '-110%' : '-70%'}) scaleY(0);`};

  a {
    font-size: ${({ theme }) => theme.font.size[1]};
    color: ${({ theme }) => theme.color.teal.dark};
    text-transform: uppercase;

    &:hover,
    &[data-page-active='true'] {
      color: ${({ theme }) => theme.color.teal.light};
    }
  }
`;

export const Locale = styled(Icon)`
  border-radius: 50%;
  border: 1px solid var(--border-color);
`;

export const Wrapper = styled.div`
  position: relative;
`;
