/** @format */

import { MenuItem } from '@/types/page';
import { useCallback } from 'react';

export const useGtmLabel = () => {
  const getGTMLabel = useCallback((item: MenuItem) => {
    if (!item.url) return '';
    if (!item.url.fields.link) return item.id ?? '';
    if (item.url.fields.gtmLabel) return item.url.fields.gtmLabel;
    return item.url.fields.link.fields.id;
  }, []);

  return { getGTMLabel };
};
