/** @format */

import { Translations } from '@/types/page';
import { createContext } from 'react';

export const globaltranslations: Translations['translations'] = {
  landing: {},
  'aria-label': {
    closeModal: 'close modal',
  },
  news: {
    'show-results': 'Show results',
    category: 'Category',
    year: 'Year',
    search: 'Search',
    filter_by: 'Filter by',
    filters: 'Filters',
    'no-results': "We didn't find any posts matching your search",
    'reset-filters': 'Reset filters',
    corporate: 'Corporate',
    'human-resources': 'Human resources',
    landlords: 'Landlords',
  },
  donut: {
    isa: 'ISA S.p.A.',
    seac: 'Seac Fin S.p.A.',
    other: 'Other',
    altido: 'ALTIDO',
    founder: 'Founder',
    tikehau: 'Tikehau capital',
    dovevivo: 'DoveVivo S.p.A.',
    founders: 'Founders',
    starwood: 'Starwood',
    employees: 'Employees',
    chezNestor: 'Chez Nestor',
    capitalGroup: 'Capital Group',
    dvHoldingSpa: 'DV Holding S.p.A.',
    ownedActions: 'Owned actions',
    stakeholders: 'stakeholders',
    ownedActions2: '',
    istitutoAtesino: 'Istituto Atesino di Sviluppo',
  },
  common: {
    es: 'espana',
    fr: 'france',
    it: 'italy',
    pt: 'portugal',
    uk: 'united kingdom',
    all: 'All',
    'dv-es': 'dovevivo espana',
    'dv-fr': 'dovevivo france',
    'dv-it': 'dovevivo italy',
    terms: 'Terms and Condition',
    submit: 'Submit',
    privacy: 'Privacy Policy',
    read_all: 'Read All',
    reserved: 'All rights reserved',
    'altido-it': 'altido italy',
    'altido-uk': 'altido united kingdom',
    come_back: 'Back',
    read_more: 'Read More',
    speaking_of: 'Speaking of <em>#{{category}}</em>',
    find_out_more: 'Find out more',
    filter_country: 'Filter by country',
    filter_category: 'Filter by category',
    description_joivy:
      'Joivy is the living simplification partner that provides you with the most suitable space for each moment of your life.',
    watch_video_youtube: 'Watch the video on Youtube',
  },
  'work-with-us': {
    apply: 'Apply for this role',
    fulltime: 'Full Time',
    'api-error': 'An error occured while fetching the open positions.\nTry refreshing the page or try again later.',
    internship: 'Internship',
    'no-results': 'Currently there are no open positions.\nPlease continue to follow us for future updates!',
  },
  governance: {
    joivy: 'DoveVivo S.p.A. - Capogruppo',
    dv_holding: 'DV HOLDING',
  },
  'contacts-form': {
    city: 'Property City',
    email: 'Email',
    hdyfu: 'How did you find us?',
    phone: 'Phone Number',
    nation: 'Property Nation',
    submit: 'Submit',
    company: 'Company',
    message: 'Message',
    surface: 'Surface mq',
    lastName: 'Last Name',
    firstName: 'First Name',
    chooseFile: 'Choose file',
    individual: 'Individual',
    attachments: 'Attachments',
    companyName: 'Company Name',
    requiredFieldError: 'This field is required',
  },
};

export const i18nContext = createContext(globaltranslations);
