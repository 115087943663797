/** @format */

import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes } from 'react';
import { normalizeExternalLink } from 'src/utils/normalizeExternalLink';
import { normalizeInternalLink } from '../../utils/normalizeLink';

type InboundLinkProps = {
  children?: React.ReactNode;
  urlExtension?: boolean;
} & LinkProps;

const InboundLink: React.FC<InboundLinkProps> = props => {
  const { href, locale, ...rest } = props;
  const hrefStr = href.toString();
  return (
    <Link
      {...rest}
      locale={locale === 'default' ? 'en' : locale}
      href={normalizeInternalLink(hrefStr, props.urlExtension)}
    />
  );
};

const OutboundLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = props => {
  const { href, ...rest } = props;
  const isInternalAnchorRegex = /^#([\w-]+)$/;
  const isInternalRegex = /^\/[^/].*$/;

  if (isInternalAnchorRegex.test(href ?? '')) return <a {...rest} href={href} />;
  if (isInternalRegex.test(href ?? '')) return <a target="_blank" {...rest} href={href} />;

  return <a target="_blank" {...rest} href={href ? normalizeExternalLink(href) : href} />;
};

export { InboundLink, OutboundLink };
