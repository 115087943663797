/** @format */

import { InboundLink, OutboundLink } from '@/components/ui/Link';
import { ExternalLink, InternalLink } from '@/types/page';
import { Entry } from 'contentful';

interface MagicLinkProps {
  children?: React.ReactNode;
  item: Entry<InternalLink['fields'] | ExternalLink['fields']>;
  locale?: string;
  onClick?: any; // da errore ts
}

const MagicLink: React.FC<MagicLinkProps> = props => {
  const { item, children, onClick } = props;
  if ('link' in item.fields) {
    const slug = item.fields.link.fields.slug;
    const label = item.fields.label;

    return (
      <InboundLink onClick={onClick} href={`/${slug}`} {...props}>
        {children ? children : label}
      </InboundLink>
    );
  }

  const url = item.fields.url;
  const label = item.fields.label;

  return (
    <OutboundLink href={url} {...props}>
      {children ? children : label}
    </OutboundLink>
  );
};

export { MagicLink };
